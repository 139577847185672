import 'jspdf-autotable'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'
import './Yahei-normal'
// import './Yahei-normal'
export const printPDF = content => {
    import('jspdf')
        .then(() => {
            import('jspdf').then(({ jsPDF }) => {
                const doc = new jsPDF()
                doc.setFont('Yahei')
                const width = doc.internal.pageSize.getWidth()
                const height = doc.internal.pageSize.getHeight()
                const middle = width / 2
                const left = { align: 'left' }
                const center = { align: 'center' }
                const right = { align: 'right' }
                if (content.logo) {
                    const img = new Image()
                    img.src = `${VUE_APP_SERVER_ENDPOINT}/assets/${content.logo}`
                    doc.addImage(encodeURIComponent(img.src), 10, 6, 22, 22, center)
                }

                doc.setFontSize(16)
                doc.text(content.institution, middle, 15, center)
                doc.setFontSize(14)
                doc.text(content.reportName, middle, 25, center)
                doc.line(10, 30, width - 10, 30)

                doc.setFontSize(12)
                doc.text(`姓名：${content.patient.name}`, 10, 40, left)
                doc.text(`性别：${content.patient.gender}`, middle, 40, center)
                if (content.enableAge) {
                    doc.text(`年龄：${calcAge(content.patient.birthday)}`, width - 10, 40, right)
    
                }
                doc.text(`${content.numberName ? content.numberName + '：' : '编号：'}${content.patient.hospitalNumber}`, 10, 47, left)
                doc.text(
                    `${content.bedNumberName != 'false'
                        ? content.bedNumberName == 'true'
                            ? content.typeUsername == '1'
                                ? '用户号：'
                                : '病床号：'
                            : content.bedNumberName + '：'
                        : ''
                    }${content.bedNumberName !== 'false' ? content.patient.patientNumber : ''}`,
                    middle + 2,
                    47,
                    center
                )
                doc.text(
                    `${content.partition !== 'false' ? (content.partition == 'true' ? '分区：' : content.partition + '：') : ''}${content.partition !== 'false' ? content.patient.bedNumber : ''
                    }`,
                    width - 10,
                    47,
                    right
                )
                doc.line(10, 52, width - 10, 52)

                // 计算表格左右两半的数据
                const tableData = genMocaTableData(content)
                // var tableData = ""
                const mmseTableData = genTableData(content)
                var halfLength = content.id === '1' || content.id === 1 ? Math.ceil(mmseTableData.length / 2) : Math.ceil(tableData.length / 2)
                var leftData = content.id === '1' || content.id === 1 ? mmseTableData.slice(0, halfLength) : tableData.slice(0, halfLength)
                var rightData = content.id === '1' || content.id === 1 ? mmseTableData.slice(halfLength) : tableData.slice(halfLength)
                if (content.id === '1' || content.id === 1) {
                    const tableData = genTableData(content)

                    doc.autoTable({
                        tableWidth: (width - 20) / 2,
                        theme: 'striped',
                        styles: { font: 'Yahei', fontSize: 10 },
                        margin: { left: 10, right: 10 },
                        startY: 55,
                        head: [['编号', '评分项目', { content: '正确计分', styles: { halign: 'center' } }]],
                        body: leftData,
                        bodyStyles: {
                            fontSize: 9
                        }
                    })
                    doc.autoTable({
                        tableWidth: (width - 20) / 2,
                        theme: 'striped',
                        styles: { font: 'Yahei', fontSize: 10 },
                        margin: { left: (width - 20) / 2 + 10, right: 10 },
                        startY: 55,
                        head: [['编号', '评分项目', { content: '正确计分', styles: { halign: 'center' } }]],
                        body: rightData,
                        bodyStyles: {
                            fontSize: 9
                        }
                    })
                    doc.setDrawColor(128, 128, 128)
                    doc.line((width - 20) / 2 + 10, 55, (width - 20) / 2 + 10, tableData.length * 5.68)
                } else {
                    doc.autoTable({
                        tableWidth: (width - 20) / 2,
                        theme: 'striped',
                        styles: { font: 'Yahei', fontSize: 10 },
                        margin: { left: 10, right: 10 },
                        startY: 55,
                        head: [['编号', '评分项目', { content: '正确计分', styles: { halign: 'center' } }]],
                        body: leftData,
                        bodyStyles: {
                            fontSize: 10
                        }
                    })
                    doc.autoTable({
                        tableWidth: (width - 20) / 2,
                        theme: 'striped',
                        styles: { font: 'Yahei', fontSize: 10 },
                        margin: { left: (width - 20) / 2 + 10, right: 10 },
                        startY: 55,
                        head: [['编号', '评分项目', { content: '正确计分', styles: { halign: 'center' } }]],
                        body: rightData,
                        bodyStyles: {
                            fontSize: 10
                        }
                    })
                    doc.setDrawColor(128, 128, 128)
                    doc.line((width - 20) / 2 + 10, 55, (width - 20) / 2 + 10, tableData.length * 5.88)
                }
                const resultData = getResultTableData(content)
                const mmseResultData = getMMSEResultTableData(content)
                doc.autoTable({
                    theme: 'plain',
                    styles: { font: 'Yahei', fontSize: 12, fillColor: [253, 253, 253] },
                    headStyles: { fillColor: [41, 128, 186], cellPadding: [2, 0.2, 2, 2.2], textColor: [255, 255, 255] },
                    margin: { left: 10, right: 10 },
                    startY:
                        content.id === '1' || content.id === 1
                            ? genMocaTableData(content).length * 5.68 + 10
                            : genMocaTableData(content).length * 5.88 + 10,
                    head: [['结果', '', '', '', '']],
                    body: content.id === '1' || content.id === 1 ? mmseResultData : resultData,
                    bodyStyles: {
                        fontSize: 10,
                        lineWidth: 0.1,
                        textColor: [80, 80, 80]
                    }
                })

                doc.setFontSize(8)
                doc.text(`打印时间：${calcDateTime(new Date())}`, 10, height - 15, left)
                doc.text('*此报告仅供临床参考', 10, height - 10, left)
                doc.setFontSize(12)
                doc.text('签字：', width / 2 + 20, height - 12, left)

                window.open(doc.output('bloburl'), '_blank')
            })
        })
        .catch(err => console.log(err))
}

// let drawCell = function(data) {
//   var doc = data.doc
//   var rows = data.table.body
//   if (data.row.index === rows.length - 1) {
//     doc.setTextColor(255, 255, 255)
//     doc.setFillColor(41, 128, 186)
//   }
// }

function genTableData(content) {
    const _treatments = content.treatments
    return _treatments.reduce((p, c) => {
        p.push([
            p.length + 1 <= 30 ? p.length + 1 : c.question,
            p.length + 1 <= 30 ? c.question : '',
            { content: c.answer, styles: { halign: 'center' } }
        ])
        return p
    }, [])
}

function genMocaTableData(content) {
    const _treatments = content.treatments
    return _treatments.reduce((p, c, i) => {
        p.push([
            p.length + 1 <= 30 ? p.length + 1 : c.question,
            p.length + 1 <= 30 ? c.question[i] : '',
            { content: c.answer, styles: { halign: 'center' } }
        ])
        return p
    }, [])
}

function getResultTableData(content) {
    return [
        [
            { content: '得分', rowSpan: 2, styles: { halign: 'center', valign: 'middle' } },
            {
                content: content.mocaScore,
                styles: {
                    halign: 'center',
                    valign: 'middle',
                    cellPadding: [5, 0, 5, 0],
                    fontSize: 22,
                    textColor: content.mocaScore >= 26 ? [75, 213, 73] : [255, 0, 0]
                }
            },
            { content: '参考划界分', rowSpan: 2, styles: { halign: 'center', valign: 'middle' } },
            { content: content.mocaGrade[0].grade, styles: { halign: 'center', valign: 'middle' } },
            { content: content.mocaGrade[0].name, styles: { halign: 'center', valign: 'middle' } }
        ],
        [
            {
                content: content.mocaScore >= 26 ? '认知功能正常' : '认知功能障碍',
                styles: { halign: 'center', valign: 'middle', cellPadding: [5, 0, 5, 0] }
            },
            { content: content.mocaGrade[1].grade, styles: { halign: 'center', valign: 'middle' } },
            { content: content.mocaGrade[1].name, styles: { halign: 'center', valign: 'middle' } }
        ]
    ]
}

function getMMSEResultTableData(content) {
    return [
        [
            { content: '得分', rowSpan: 4, styles: { halign: 'center', valign: 'middle' } },
            {
                content: content.mmseScore,
                rowSpan: 2,
                styles: { halign: 'center', valign: 'middle', fontSize: 22, textColor: getColor(content.mmseScore) }
            },
            { content: '参考划界分', rowSpan: 4, styles: { halign: 'center', valign: 'middle' } },
            { content: content.mmseGrade[0].grade, styles: { halign: 'center', valign: 'middle' } },
            { content: content.mmseGrade[0].name, styles: { halign: 'center', valign: 'middle' } }
        ],
        [
            { content: content.mmseGrade[1].grade, styles: { halign: 'center', valign: 'middle' } },
            { content: content.mmseGrade[1].name, styles: { halign: 'center', valign: 'middle' } }
        ],
        [
            {
                content: getResult(content.mmseScore),
                rowSpan: 2,
                styles: { halign: 'center', valign: 'middle' }
            },
            { content: content.mmseGrade[2].grade, styles: { halign: 'center', valign: 'middle' } },
            { content: content.mmseGrade[2].name, styles: { halign: 'center', valign: 'middle' } }
        ],
        [
            { content: content.mmseGrade[3].grade, styles: { halign: 'center', valign: 'middle' } },
            { content: content.mmseGrade[3].name, styles: { halign: 'center', valign: 'middle' } }
        ]
    ]
}

function calcAge(dateString) {
    var birthday = +new Date(dateString)
    return ~~((Date.now() - birthday) / 31557600000)
}

function getResult(score) {
    let tipText = ''
    if (score >= 27 && score <= 30) {
        tipText = '认知功能正常'
    } else if (score >= 21 && score <= 26) {
        tipText = '轻度认知功能障碍'
    } else if (score >= 10 && score <= 20) {
        tipText = '中度认知功能障碍'
    } else {
        tipText = '重度认知功能障碍'
    }
    return tipText
}

function getColor(score) {
    let color = ''
    if (score >= 27 && score <= 30) {
        color = '#4BD549'
    } else if (score >= 21 && score <= 26) {
        color = '#FAD984'
    } else if (score >= 10 && score <= 20) {
        color = '#FF9800'
    } else {
        color = '#E02A1F'
    }
    return color
}
// function getName(name) {
//   if (name) {
//     return name
//   }
// }

function calcDateTime(dateString, displayTime = true) {
    const date = new Date(dateString)
    if (displayTime) {
        return date.toLocaleDateString('zh-CN') + ' ' + date.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })
    } else {
        return date.toLocaleDateString('zh-CN')
    }
}
